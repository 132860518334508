import React from "react";
import {
  PageTitle,
  PageUpdateDate,
  PageWrapper,
  ParagraphContent,
  ParagraphSubTitle,
  ParagraphTitle,
} from "styles/PageMember";

const TermsOfService = () => {
  return (
    <PageWrapper>
      <PageTitle className="mb-8">삼분의일 서비스 이용약관</PageTitle>
      <PageUpdateDate className="mb-32">업데이트 : 2023/01/03</PageUpdateDate>
      {/* 1장 */}
      <ParagraphTitle className="mb-8">제 1장. 총칙</ParagraphTitle>
      <ParagraphSubTitle className="mb-8">제 1조. 목적</ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 이용약관(이하 “약관”이라 함)은 약관에 동의한 이용자(이하 “회원”이라
        함)가 (주)삼분의일(이하 “회사”라 함)이 제공하는 수면관리 서비스인
        슬립큐브 매트리스(이하 ”서비스”라 함)를 이용함에 있어 회사와 회원 간의
        권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">제 2조. 정의</ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다
        <br />
        “회사”라 함은 서비스 개발, 운영 및 회원정보를 관리하는
        (주)삼분의일을의미합니다.
        <br />
        “회원”이라 함은 회사의 서비스에 접속, 본 약관에 동의하고 회사와
        이용계약을 체결하여 회사가 제공하는 서비스를 이용하는 자를 의미합니다.
        <br />
        “서비스”라 함은 구현되는 단말기(개인용 컴퓨터, TV, 이동전화, 스마트폰
        등의 각종 유무선 장치를 포함)와 상관 없이 회원이 이용할 수 있는 서비스
        모두를 의미합니다.
        <br />
        “애플리케이션”이라 함은 단말기에 설치하여 서비스를 이용할 수 있도록
        구성된 소프트웨어를 의미합니다.
        <br />본 약관에서 사용하는 용어 중 정하지 아니한 것은 관계 법령 및
        서비스 별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례 및 상거래
        관행에 따릅니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 3조. 약관의 명시와 효력 및 개정
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 약관은 본 서비스 이용 회원에 대하여 적용됩니다.
        <br />
        회사는 본 약관의 내용을 회원이 알 수 있도록 서비스 화면에 게시하거나
        기타 유사한 방법으로 고지하고, 회원이 이를 동의함으로써 효력이
        발생합니다.
        <br />
        본 약관은 회원이 서비스 화면에 고지된 본 약관의 ’동의함(필수)’을
        선택하는 경우에만 동의한 것으로 간주합니다.
        <br />
        회사는 필요하다고 인정하는 경우 관계법령에 위배되지 않는 범위 내에서 본
        약관을 개정할 수 있습니다. 개정약관은 전항의 방식으로 적용일자 및
        개정사유를 명시하여 현행약관과 함께 그 적용일자 14일 이전부터 적용일
        전일까지 공지합니다. 다만, 회원에게 불리한 약관 개정의 경우 공지 외에
        로그인 시 동의 창, 회원이 서비스 가입 시 기재한 전자우편 등 전자적
        수단을 통해 명확히 통지합니다.
        <br />
        회원은 개정약관의 적용에 동의하지 않는 경우 이용계약의 해지를 요청할 수
        있습니다. 다만, 회원이 변경된 약관의 효력발생일 이후에도 본 서비스를
        계속 사용할 경우 약관의 변경에 동의한 것으로 간주됩니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 4조. 약관 외 준칙
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 독점규제
        및 공정거래에 관한 법률, 저작권법 등 관계법령 및 회사가 제공하는
        서비스에 관한 별도의 약관, 이용규정 또는 세부이용지침 등의 규정에
        따릅니다.
        <br />
        회사는 서비스 이용을 위한 개별 서비스에 대해서는 별도의 이용약관 및
        정책을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 그러한 별도의
        이용약관 및 정책이 우선하여 적용됩니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 5조. 회원에 대한 통지
      </ParagraphSubTitle>
      <ParagraphContent className="mb-32">
        회사는 회원정보에 기재된 전자우편이나 문자메시지(SMS/MMS), 애플리케이션
        알림(PNS)의 발송, 기타 적절한 통신수단을 이용하여 회원에 대한 통지를 할
        수 있습니다.
        <br />
        회사는 전항에 의한 통지가 불가능한 불특정다수 회원 또는 이용자에 대한
        통지의 경우 서비스 게시판 등에 관련 내용을 상당 기간 게시함으로써 개별
        통지에 갈음할 수 있습니다.
      </ParagraphContent>
      {/* 2장 */}
      <ParagraphTitle className="mb-8">제 2장. 서비스의 이용</ParagraphTitle>
      <ParagraphSubTitle className="mb-8">
        제 6조. 서비스 이용의 승낙
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        서비스를 이용하고자 하는 자는 회원가입을 마쳐야 합니다. 회사는 전항을
        충족하고 본 약관에 동의하는 자에게 서비스 이용의 승낙을 원칙으로 합니다.
        <br />
        회원은 서비스 이용을 원할 경우 현재의 사실과 일치하는 본인의 완전한
        정보를 제공하고 해당 정보가 변경되었을 경우 회원정보 변경 등 회사가 정한
        절차에 따라 이를 수정해야 할 의무가 있습니다. 회원이 정보를 변경하지
        않음으로써 발생하는 문제에 대하여 회사는 책임을 지지 않습니다.
        <br />
        회원은 반드시 회원 본인의 정보를 제공하여야만 서비스를 이용할 수
        있습니다. 타인의 개인정보를 이용 또는 도용하거나 허위 정보를 등록하는 등
        본인의 정보를 정확히 등록하지 않은 회원은 본 약관에 따라 해지 등
        불이익을 받을 수 있으며, 관계 법령에 따라 처벌 받을 수 있습니다.
        <br />
        회사는 회원이 등록한 정보에 대하여 확인조치를 할 수 있으며 회원은 회사의
        확인조치에 대하여 적극 협력하여야 합니다. 만일 이를 이행하지 아니할 경우
        회사는 회원이 등록한 정보를 부정확한 것으로 처리할 수 있으며 이로 인해
        발생하는 모든 문제에 대해서 회사는 책임을 지지 않습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 7조. 서비스 승낙의 제한
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 회원이 다음 각 호에 해당하는 경우에 대하여 이용을 승낙하지 않거나
        이미 승낙했더라도 언제든지 이용계약을 해지할 수 있습니다.
        <br />
        ① 회사의 상품 보유 고객이 아닌 경우
        <br />
        ② 회사에서 공시한 서비스 이용 자격 조건(상품 보유 여부, 성실한 할부 금액
        지불 여부 등) 에 부합하지 않는 경우
        <br />
        ③ 기타 서비스 이용 필수 조건 (본인 명의 스마트폰 사용, 앱 설치 가능
        스마트폰 기종 사용 등)에 부합하지 않는 경우
        <br />
        ④ 회사의 설비나 기술, 정책 상 서비스 제공이 불가능하거나 중단될 경우
        <br />
        ⑤ 타인의 명의사용 등 이용자의 회원정보가 허위로 기재된 경우
        <br />
        ⑥ 회원정보 등 서비스 신청 및 이용 시 필요한 사항을 허위로 기재하거나
        중대한 사항에 누락, 오기가 있는 경우
        <br />
        ⑦ 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 서비스를
        이용하고자 하는 경우
        <br />
        ⑧ 버그 및 악성 프로그램을 이용하거나 시스템 취약점을 악용하여 부정한
        방법으로 사용하고자 하는 경우
        <br />
        ⑨ 위법, 불법 행위 등 부정한 용도로 서비스를 이용하고자 하는 경우
        <br />
        ⑩ 회사의 사전 동의 없이 영리를 추구할 목적으로 서비스를 이용하고자 하는
        경우
        <br />
        ⑪ 회원의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을
        침해하거나 침해할 우려가 있는 경우
        <br />
        ⑫ 이전에 부정 이용 등으로 인하여 회원 자격을 상실한 적이 있는 경우.
        다만, 동 자격 상실 이후 1년 이상 경과한 자로 회사의 회원 재가입 승낙을
        받은 경우는 예외로 합니다.
        <br />
        ⑬ 본 서비스와 경쟁관계에 있는 자가 서비스를 이용하고자 하는 경우
        <br />
        ⑭ 기타 회사의 내부 서비스 제공 기준과 회사의 정책에 적합하지 않는
        회원으로 판단되는 경우나 서비스 제공이 곤란한 경우
        <br />⑮ 만 15세 미만인 경우
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 8조. 개인정보보호
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
        노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의
        개인정보 처리방침이 적용됩니다.
        <br />
        회원은 비밀번호 등 회원의 정보가 타인에게 노출되지 않도록 철저히
        관리해야 하며 회사는 회원의 귀책사유로 인해 노출된 정보와 이로 인해
        발생하는 문제에 대해 책임을 지지 않습니다.
        <br />
        회사는 필요 시 전문기관 또는 녹취, 서명된 서비스 신청서 등을 통한 회원의
        실명 확인 및 본인인증 및 서비스 가입 동의를 요청할 수 있습니다.
        <br />
        회사는 회원의 개인정보를 이용자의 동의 없이 목적 외의 이용이나 제3자에게
        제공할 수 없습니다. 다만, 다음 각 호에 해당하는 경우 대하여 법이
        허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.
        <br />
        ① 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우
        <br />
        ② 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우
        <br />
        ③ 회원의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호
        업무를 위해 필요한 경우
        <br />
        ④ 기타 법률의 규정 또는 법률에 의하여 요구되는 불가피한 사유가 있는 경우
        <br />
        회사는 서비스를 중단하거나 회원이 개인정보 제공 동의를 철회한 경우에는
        신속하게 회원의 개인정보를 파기합니다. 단, 전자상거래 등에서의 소비자
        보호에 관한 법률 등 관련 법률에서 정하는 바에 따라 일정 정보는 보관할 수
        있습니다.
        <br />
        회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의
        하에 관계 법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수
        있습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 9조. 기기의 연동
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회원은 서비스 가입 후 회사가 서비스 어플리케이션과 연동을 지원하는
        다양한 기기(스마트 블라인더, 에어콘, 각종 웨어러블 스마트 기기 등)를
        구매하여 서비스와 연동되게 할 수 있습니다.
        <br />
        회사는 계측기에 의하여 측정된 정보의 수집, 보관을 수행합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 10조. 서비스의 개시
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 회원이 애플리케이션에 로그인 후 서비스를 즉시 제공하며, 고객이
        제공하고 회사가 보유한 정보에 기반하여 공시한 서비스 제공 조건에
        부합하지 않는 경우 서비스를 제한 할 수 있습니다. 단, 일부 서비스에 한해
        지정된 일자부터 서비스를 개시할 수 있습니다.
        <br />
        회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는
        경우에는 회사 대표 사이트에 공지하거나 회원에게 이를 통지합니다.
        <br />
        회사는 모바일 단말기(안드로이드, iOS 단말기) 전용 애플리케이션을
        제공하며 회원은 회사에서 허용하는 범위 내의 단말기에서 전용
        애플리케이션을 다운받고 설치하여 본 서비스를 이용할 수 있습니다. 단,
        단말기의 종류 및 회원이 가입한 통신사에 따라 애플리케이션의 구성과
        기능에는 차이가 있을 수 있습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 11조. 서비스의 이용시간
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 서비스를 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일
        24시간 운영함을 원칙으로 합니다. 단, 회사가 사전에 시스템 정기점검, 증설
        및 교체, 서비스 개편 등 운영상 필요한 경우에 한해 사전에 지정한 날이나
        시간에 서비스를 일시 중단할 수 있으며 이로 인한 서비스 일시 중단은
        회원에게 사전에 통지합니다.
        <br />
        회사는 서비스 내 일부 기능의 경우 별도로 정해진 기간 및 시간 등의 기준에
        의해 운영할 수 있습니다. 별도로 정해진 기준은 회원에게 사전에
        통지합니다.
        <br />
        회사는 긴급한 시스템 점검, 증설 및 교체, 설비의 장애, 통신 두절,
        천재지변 등 운영상 상당한 이유가 있는 경우 사전 예고 없이 일시적으로
        서비스의 전부 또는 일부를 중단할 수 있으며 사후에 통지합니다.
        <br />
        회사는 본 조 제 1항 내지 3항에 의한 서비스 중단에 대하여 회원 또는 제
        3자가 입은 손해를 책임지지 않습니다.
        <br />
        회사는 본 조 각 항에 기재된 통지의 수단과 방법으로 본 약관의 제 5조에서
        정한 수단과 방법을 사용합니다
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 12조. 서비스의 내용
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        보건복지부 비의료 건강관리 서비스 가이드라인에 따라 비의료기관은 질환의
        치료 목적의 서비스가 아닌 보조적 서비스를 제공할 수 있습니다.
        <b>
          &nbsp;본 서비스는 질환의 치료 목적의 서비스가 아닌 보조적 건강관리
          서비스로 진단/치료 등 의료적 판단을 제공하지 않습니다.&nbsp;
        </b>
        또한 개인의 질병 상태에 효과 및 잠재적인 위험성이 다를 수 있어 의사와
        상담 후 서비스를 이용하도록 권장드립니다.
        <br />
        본 서비스는 다음 각 호와 같은 기능을 제공합니다.
        <br />
        ① 수면시간을 자동으로 측정하고 기록, 저장하는 기능
        <br />
        ② 기상 시 현재의 기분을 입력하는 기능
        <br />
        ③ 입력한 내용을 바탕으로 통계를 보여주는 기능
        <br />
        ④ 기타 입력된 기록에 대한 분석과 해당 기록을 바탕으로 한 정보 제공 기능
        <br />본 서비스는 기본적으로 유료 서비스이나 회사가 지정한 특정 인물,
        단체에 한해 일부 또는 전체 기능을 무료로 제공할 수 있습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 13조. 서비스의 변경 및 제한
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 서비스의 전부 또는 일부를 회사의 정책 및 운영의 필요상 변경,
        중단할 수 있으며 변경된 내용 및 제공일자를 통지합니다.
        <br />
        회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
        중지할 수 있습니다.
        <br />
        ① 시스템 정기점검, 증설 및 교체, 서비스 개편 등 운영상 필요한 경우
        <br />
        ② 긴급한 시스템 점검, 증설 및 교체, 설비의 장애, 통신 두절 등 운영상
        상당한 이유가 있는 경우
        <br />
        ③ 기타 정전, 설비의 장애, 천재지변 등으로 인해 정상적인 서비스가
        불가능한 경우
        <br />
        ④ 회사의 제반 사정으로 서비스를 유지할 수 없는 경우
        <br />
        ⑤ 회사의 사업계획의 변경 또는 경영상의 사유로 더 이상의 서비스 제공이
        불가능할 경우
        <br />
        회사는 서비스를 중지한 경우 사전에 통지해야 합니다. 단, 회사가 통제할 수
        없는 사유로 인해 서비스가 중단되어 사전 통지가 불가능한 경우에는 사후에
        통지합니다.
        <br />
        회사는 고의 또는 과실이 없는 경우에는 서비스의 변경, 중지로 인해
        발생하는 문제에 대해서 책임을 지지 않습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 14조. 게시물의 관리
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        게시물이라 함은 회원이 서비스를 이용하면서 서비스 내에 게시한 글,
        이미지, 각종 파일과 댓글, 링크 등을 의미합니다.
        <br />
        회사는 회원의 게시물이 정보통신망 이용 촉진 및 이용자 보호 등에 관한
        법률(이하 정통망법), 저작권법 등 관련법에 위반되는 내용을 포함하는 경우
        관련법에서 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등 조치를
        취할 수 있습니다.
        <br />
        회사는 회원이 작성한 게시물이 다음 각 호의 경우에 해당한다고 판단되는
        경우 해당 게시물을 삭제할 수 있으며 회원의 서비스 이용 중지 또는 자격을
        제한을 할 수 있습니다.
        <br />
        ① 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
        유통시키거나 상업적으로 이용하는 경우
        <br />
        ② 회원의 게시물이 정통망법 및 저작권법 등 관련법에 위반되는 내용을
        포함하는 경우
        <br />
        ③ 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 요청이 들어
        온 경우
        <br />
        ④ 음란물을 포함하거나 음란사이트를 연결(링크)하는 경우
        <br />
        ⑤ 서비스와 관련된 설비의 오작동이나 정보 등의 파괴 및 혼란을 유발시키는
        내용, 컴퓨터 바이러스 감염자료를 등록하는 경우
        <br />
        ⑥ 회사에서 공식적으로 인정한 서비스 이외의 서비스를 이용하여 상품을
        판매하는 영업 활동, 광고를 통한 수익, 음란사이트를 통한 상업행위,
        상용소프트웨어 불법배포, 해킹을 하는 경우
        <br />
        ⑦ 본 약관에 따른 회원의 의무에 위반되거나, 게시판의 성격에 부합하지 않는
        경우
        <br />
        ⑧ 기타 관계법령에 위배된다고 판단되는 경우
        <br />
        회원이 게시한 게시물로 인해 발생하는 모든 손실이나 법적 책임은 회원이
        부담합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 15조. 게시물의 저작권
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사가 작성하여 서비스를 목적으로 애플리케이션 등에 게시 및 제공한
        저작물은 저작권, 지적재산권을 포함한 일체의 권리가 회사에 귀속됩니다.
        <br />
        회원이 서비스를 이용하는 과정에서 작성한 게시물(회원간 전달 포함) 등에
        대한 저작권, 지적재산권을 포함한 일체의 권리는 별도의 의사표시가 없는 한
        각 회원에게 귀속되며 해당 게시물에 대한 책임도 회원에게 있습니다.
        <br />
        회사는 작성한 회원의 동의 없이 게시물을 다른 목적으로 사용하지 않습니다.
        <br />
        회사는 특정 회원에 대한 서비스 관련 이용계약이 적법하게 해지된 경우 해당
        회원의 게시물을 삭제할 수 있습니다.
        <br />
        회원은 서비스에 게시된 자료 또는 서비스를 이용하여 얻은 정보를 회사의
        사전승낙 없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의해
        영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한
        지식재산권 침해는 관계법령의 적용을 받습니다.
        <br />
        회원이 등록한 게시물이 회사 또는 제 3자의 권리를 침해하여 발생하는
        분쟁의 책임은 전적으로 회원이 부담하여야 합니다.
        <br />
        회원은 회원이 제 3자의 권리를 침해하였음을 이유로 회사가 손해배상청구 등
        이의제기를 받은 경우 회사의 면책을 위하여 노력하여야 합니다. 회사가
        면책되지 못한 경우 회원은 회사에 발생한 손해를 부담하여야 합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 16조. 정보의 제공과 게재
      </ParagraphSubTitle>
      <ParagraphContent className="mb-32">
        회사가 타 업체와 사업 제휴, 사업 양도, 회사의 분할, 합병 시 회원의
        정보는 공유될 수 있습니다.
        <br />
        회사는 광고를 포함하여 회원이 서비스 이용 중 필요하다고 인정되는 다양한
        정보를 공지사항, 전자 우편, 핸드폰 문자 메시지(SMS, MMS), 우편 ,
        애플리케이션 알림(PNS)의 발송, 전화 등 기타 적절한 통신수단 등의
        방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른
        거래관련 정보, 고객문의 답변 등을 제외하고는 언제든지 수신 거절을 할 수
        있습니다.
        <br />
        회사는 서비스 화면 등 회사가 지정하는 위치에 광고를 포함한 다양한 정보를
        게재할 수 있으며 회원은 회사에서 게재하는 게시물에 대한 임의 삭제, 비방,
        기타 방해 행위 등을 할 수 없습니다.
        <br />
        회사는 회사나 회원이 작성한 게시물 중 하이퍼링크 등으로 연결된 사이트가
        제공하는 재화, 용역 등에 대한 거래에 대해서 보증하지 않으며 책임을 지지
        않습니다.
      </ParagraphContent>
      {/* 3장 */}
      <ParagraphTitle className="mb-8">제 3장. 회사의 의무</ParagraphTitle>
      <ParagraphSubTitle className="mb-8">
        제 17조. 회사의 의무
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며
        이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데
        최선을 다하여야 합니다.
        <br />
        회사는 서비스 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙 없이
        제 3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의
        목적으로 관계기관으로부터 요구 받은 경우나 주무관청의 요청이 있는 경우
        등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.
        <br />
        회사는 계속적이고 안정적인 서비스 제공을 위하여 관련 설비 및
        소프트웨어에 장애가 발생한 때에는 부득이한 사유가 없는 한 지체 없이 이를
        수리 또는 복구합니다. 다만, 천재지변, 비상사태 또는 그밖에 부득이한
        경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다.
        <br />
        회사는 개인정보보호법, 정통망법, 통신비밀보호법, 전기통신사업법 등
        서비스의 운영, 유지와 관련 있는 대한민국 법규를 준수합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 18조. 회원의 의무
      </ParagraphSubTitle>
      <ParagraphContent className="mb-32">
        회원은 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에
        해당하는 행위를 하여서는 안됩니다. 회사는 회원이 아래 각 호의 행위를
        하는 경우 회원의 서비스 이용을 제한할 수 있으며 수사 기관에의 고발 등
        합당한 조치를 취할 수 있습니다.
        <br />
        ① 서비스를 이용하기 위하여 회원가입 및 회원정보 변경 시에 허위 정보를
        등록하거나 정보를 누락 또는 부정확하게 등록하는 행위
        <br />
        ② 타인으로 가장하거나 명의를 도용하는 행위, 타인의 명예를 손상시키거나
        타인과의 관계를 허위로 명시하는 행위, 타인에게 불이익을 주는 행위
        <br />
        ③ 회사나 제 3자의 저작권 등 기타 권리를 침해하는 행위
        <br />
        ④ 회사의 서비스에 게시된 정보를 회사의 사전 승낙 없이 영리 또는 비영리의
        목적으로 복제, 출판, 방송 등에 이용하거나 제 3자에게 제공하는 행위
        <br />
        ⑤ 회사가 정한 정보 이외에 관련 법령에 의하여 게시가 금지되는 정보(컴퓨터
        프로그램 포함)의 게시 행위
        <br />
        ⑥ 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
        파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일,
        프로그램을 포함하고 있는 자료를 게시하는 행위
        <br />
        ⑦ 불법 복제 또는 해킹을 조장하는 행위, 리버스엔지니어링, 디컴파일,
        디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는
        모방 기타 변형하는 행위, 자동 접속 프로그램 등을 사용하는 등 정상적인
        용법과 다른 방법으로 서비스를 이용하여 "회사"의 서버에 부하를 일으켜
        회사의 정상적인 서비스를 방해하는 행위, 서비스 또는 프로그램상의 버그 등
        이에 준하는 것을 이용하여 서비스를 이용하는 행위
        <br />
        회원은 아이디 및 비밀번호를 제 3자에게 이용하게 해서는 안됩니다. 회원은
        본인의 아이디 및 비밀번호를 도난 당하거나 제 3자가 이용하고 있음을
        인지한 경우 즉시 회사에 이를 통보하고 회사의 안내가 있는 경우에는 그에
        따라야 합니다.
        <br />
        회원은 회사의 명시적 사전 동의가 없는 한 서비스의 이용권한 및 기타
        이용계약상의 지위를 제 3자에게 양도, 증여, 대여할 수 없으며 이를 담보로
        제공할 수 없습니다.
        <br />
        회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한
        주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에
        방해되는 행위를 하여서는 아니 됩니다.
      </ParagraphContent>
      {/* 4장 */}
      <ParagraphTitle className="mb-8">
        제 4장. 계약해지 및 이용제한
      </ParagraphTitle>
      <ParagraphSubTitle className="mb-8">
        제 19조. 이용계약의 해지 및 이용제한
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        회원은 서비스 이용계약을 해지하고자 하는 때에 콜센터 전화, 애플리케이션
        회원탈퇴 기능을 이용하여 탈퇴를 요청할 수 있습니다. 회사는 회원의 탈퇴
        요청 시점에 불가피한 사정이 없는 한 즉시 탈퇴요청을 처리합니다.
        <br />
        회사는 회원이 서비스 이용계약을 해지할 경우 회원의 개인정보를 별도의
        데이터베이스에 분리하여 1년간 보관 후 모두 파기합니다.
        <br />
        회사는 회원이 다음 각 호에 해당하는 행위를 하거나 방송통신위원회 등
        관계기관의 요청이 있는 경우 사전통지 없이 회원과의 이용계약을 해지
        하거나 또는 기간을 정하여 회원의 서비스 이용을 중지할 수 있으며, 해당
        게시물을 사전 통지 없이 삭제할 수 있습니다.
        <br />
        ① 회사 또는 제 3자의 저작권, 지식재산권, 기타 권리를 침해하는 행위
        <br />
        ② 해킹 또는 컴퓨터 바이러스를 지속적으로 게시하는 행위
        <br />
        ③ 타인의 명예를 훼손하거나 타인을 모욕하는 행위
        <br />
        ④ 다른 회원의 ID를 부정 사용하는 행위
        <br />
        ⑤ 본인의 ID를 타인에게 양도하거나 매매하는 행위
        <br />
        ⑥ 정당한 사유 없이 회원 가입, 탈퇴를 수 차례 반복하여 금전적 이득을
        취하거나 회사의 서비스 운영에 지장을 초래하는 행위
        <br />
        ⑦ 서비스 운영에 지장을 주거나 줄 우려가 있는 일체의 행위 및 본 약관,
        기타 관계 법령에 위배되는 행위
        <br />
        회사는 회원이 서비스 이용 자격 조건에 해당하는 상품 계약을 해지할 경우
        서비스 이용계약을 해지하거나 이용을 제한할 수 있습니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">제 20조. 휴면 회원</ParagraphSubTitle>
      <ParagraphContent className="mb-32">
        회원이 1년 이상 서비스를 이용하지 않은 경우 회원의 계정을 휴면상태로
        전환하고, 해당 계정의 회원정보를 다른 회원의 정보와 분리하여 별도
        보관합니다. 서비스 이용이란 서비스 앱 실행과 같이 직접적인 행동을 통해
        서비스 화면에 완전하게 접속되는 경우를 말합니다.
        <br />
        휴면 상태의 계정으로는 서비스 이용이 제한되며, 법령에 규정된 경우를
        제외하고는 회사는 휴면상태로 전환된 개인/민감정보를 이용∙제공하지
        않습니다.
        <br />
        회사는 처리예정일로부터 30일 이전에 해당 사실을 회원에게 사전 통지하고
        휴면계정 전환 이후에 회원은 서비스에 접속하여 회사가 정한 별도의 절차를
        거쳐 휴면을 해제할 수 있습니다.
        <br />
        휴면 회원 상태에서 제19조에서 규정한 서비스 해지 상황이 발생하면,
        제19조에서 정한 사항대로 해지 처리됩니다.
      </ParagraphContent>
      {/* 5장 */}
      <ParagraphTitle className="mb-8">제 5장. 책임의 제한</ParagraphTitle>
      <ParagraphSubTitle className="mb-8">제 21조. 면책 조항</ParagraphSubTitle>
      <ParagraphContent className="mb-32">
        회사는 천재지변 또는 이에 준 하는 불가항력으로 인하여 서비스를 제공할 수
        없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
        <br />
        회사는 회원이 약관, 서비스 이용방법 및 이용 기준을 준수하지 않은 결과
        또는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대하여는
        책임을 지지 않습니다.
        <br />
        회사는 이용자 상호간 또는 이용자와 제3자 간에 발생한 분쟁 등에 대하여
        책임을 지지 않습니다.
        <br />
        회사가 회원에게 제공하는 서비스는 보건복지부 건강 관리 서비스
        가이드라인을 준수합니다. 따라서 서비스는 개별 회원에 대한 의료적 판단이
        포함된 서비스가 아니며 제공된 정보나 자료는 의료진의 진단이나 처방,
        상담, 치료를 대체할 수 없습니다.
        <br />
        서비스를 통하여 제공되는 정보는 건강 관리를 돕는 것만을 목적으로 하며,
        일부 정보는 특정 회원에게는 부적절할 수 있습니다. 따라서 서비스 이용 전
        주치의와 건강 상의 위해성에 대해 상담을 마치신 후 활용하시는 것을
        권고합니다. 회사는 특정 질병을 앓고 있는 회원이 서비스를 통해 제공되는
        정보를 이용하거나 이를 바탕으로 관련 행위를 이행함에 따라 발생하는
        손해에 대해 책임지지 않습니다.
        <br />
        사용 중 이상반응을 감지하는 경우 즉시 사용을 중지하고, 의료진의 상담을
        받는 것을 권고합니다. 회사는 회원이 건강 상의 이상변화를 느꼈음에도
        (예를 들어 저혈당 징후) 의료진의 상담 없이 서비스를 이용하거나 의료진의
        권고를 무시하여 발생하는 손해에 대해서는 책임지지 않습니다.
        <br />
        본 서비스는 질병과 관련된 일반적 지식을 제공할 뿐, 회원의 개별 건강
        상태에 대한 의료적 판단을 제공하지 않습니다. 따라서 회사는 회원의 응급
        의료 상황 발생 시 응급 구조원 서비스 등을 포함한 의료 관리에 대해서
        책임지지 않습니다.
        <br />
        회사는 서비스가 제공한 정보나 자료의 정확성, 완전성, 신뢰성 등에 대해
        보증하거나 책임 지지 않습니다. 또한, 회사는 회원이 서비스 내에서 게시
        또는 전송한 정보나 자료의 정확성, 완전성, 신뢰성에 대해 책임 지지
        않습니다.
        <br />
        회사는 회원이 서비스 이용으로 얻게 될 정보나 자료로 인한 어떠한 손해도
        책임지지 않습니다.
        <br />
        서비스에서 제공하는 건강정보는 해당 저자의 견해이며, 회사의 공식적인
        의견을 대신하지 않습니다.
      </ParagraphContent>
      {/* 6장 */}
      <ParagraphTitle className="mb-8">제 6장. 분쟁의 해결</ParagraphTitle>
      <ParagraphSubTitle className="mb-8">
        제 22조. 준거법 및 관할법원
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 약관은 대한민국 법령에 의하여 규정되고 이행됩니다.
        <br />
        회사와 회원간에 발생한 분쟁으로 인하여 소송이 제기되는 경우 회사의 본점
        소재지를 관할하는 법원을 합의 관할로 합니다.
      </ParagraphContent>
      <ParagraphSubTitle className="mb-8">
        제 23조. 규정의 준용
      </ParagraphSubTitle>
      <ParagraphContent className="mb-8">
        본 약관에 명시되지 않은 사항에 대해서는 대한민국의 관련법령에 의하고,
        법에 명시되지 않은 부분에 대하여는 상관습에 따릅니다.
      </ParagraphContent>
    </PageWrapper>
  );
};

export default TermsOfService;
