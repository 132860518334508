import React from "react";
import {
  PageTitle,
  PageUpdateDate,
  PageWrapper,
  ParagraphContent,
} from "styles/PageMember";

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <PageTitle className="mb-8">
        개인(신용)정보의 수집∙이용에 관한 사항
      </PageTitle>
      <PageUpdateDate className="mb-32">업데이트 : 2023/01/03</PageUpdateDate>
      <ParagraphContent>
        고객님은 본 동의를 거부하실 수 있습니다. 단, 동의를 거부하시는 경우에는
        삼분의일 슬립큐브 매트리스 이용이 불가합니다. 회사 및 회사 업무수탁자는
        「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라
        수면관리 서비스 제공 등을 위하여 귀하의 개인(신용)정보를 다음과 같이
        수집∙이용하고자 합니다.
        <br />
        <br />
        - 개인(신용)정보의 수집∙이용 목적: 서비스 인용 자격 확인, 서비스 이용
        인증, 서비스 제공과 변경 및 이를 위한 데이터 분석
        <br />
        - 수집 이용할 개인(신용)정보의 내용: 개인식별정보(성명, 생년월일,
        연락처, 주소), 담당 설계사 정보, 앱/기기 웨어러블 로그 데이터
        <br />- 개인(신용)정보의 보유 이용기간: - 서비스 이용 인증 후 해지시까지
        - 수집한 정보를 이용하고자 하는 자의 이용목적이 끝날 때까지 단,
        보유∙이용 기간이 끝나더라도 고객문의 응대 등의 목적으로 1년간 보관 후
        삭제
      </ParagraphContent>
    </PageWrapper>
  );
};

export default PrivacyPolicy;
