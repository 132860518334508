import styled from "@emotion/styled";

export const PageWrapper = styled.div`
  padding: 24px 16px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const PageTitle = styled.h1`
  font-size: 28px;
  line-height: 36px;
  color: #ebedef;
  font-weight: 700;
`;

export const PageUpdateDate = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #656e7b;
  font-weight: 400;
  word-break: break-all;
`;

export const ParagraphTitle = styled.h2`
  font-size: 22px;
  line-height: 28px;
  color: #c7cbd1;
  font-weight: 700;
  word-break: break-all;
`;

export const ParagraphSubTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #c7cbd1;
  font-weight: 700;
  word-break: break-all;
`;

export const ParagraphContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #c7cbd1;
  font-weight: 400;
  word-break: break-all;
`;
