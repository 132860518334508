import React from "react";
import {
  PageTitle,
  PageUpdateDate,
  PageWrapper,
  ParagraphContent,
} from "styles/PageMember";

const PrivacyPolicyThirdParty = () => {
  return (
    <PageWrapper>
      <PageTitle className="mb-8">개인(신용)정보의 제공에 관한 사항</PageTitle>
      <PageUpdateDate className="mb-32">업데이트 : 2023/01/03</PageUpdateDate>
      <ParagraphContent>
        고객님은 본 동의를 거부하실 수 있습니다. 본 약관의 동의 여부는 언제든
        다시 거부하거나 동의하실 수 있습니다. 회사 및 회사 업무수탁자,
        자문기관은 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한
        법률」에 따라 귀하의 개인(신용)정보를 다음과 같이 제3자에게 수면관리
        서비스 제공 등을 위하여 제공하고자 합니다.
        <br />
        <br />
        - 개인(신용)정보를 제공받는 자: 업무 수탁자(서비스 제공업체,
        의료자문기관, 인센티브 제공업체, 위탁 콜센터 등)
        <br />
        - 개인(신용)정보를 제공받는 자의 이용목적: 회사의 서비스 제공을 위한
        고객 안내, 문의처리, 수집 데이터 모니터링, 서비스 독려를 위한 인센티브
        제공 등
        <br />
        - 제공할 개인(신용)정보의 내용: 개인식별정보(성명, 생년월일, 연락처),
        앱/웨어러블 로그데이터
        <br />- 제공받는 자의 개인(신용)정보 보유 이용기간: - 서비스 이용 인증
        후 해지시까지 - 수집한 정보를 이용하고자 하는 자의 이용목적이 끝날
        때까지 단, 보유∙이용 기간이 끝나더라도 고객문의 응대 등의 목적으로 1년간
        보관 후 삭제
      </ParagraphContent>
    </PageWrapper>
  );
};

export default PrivacyPolicyThirdParty;
