import React from "react";
import {
  PageTitle,
  PageUpdateDate,
  PageWrapper,
  ParagraphContent,
} from "styles/PageMember";

const CollectSensitiveInformation = () => {
  return (
    <PageWrapper>
      <PageTitle className="mb-8">민감정보 수집 이용에 관한 사항</PageTitle>
      <PageUpdateDate className="mb-32">업데이트 : 2023/01/03</PageUpdateDate>
      <ParagraphContent>
        고객님은 본 동의를 거부하실 수 있습니다. 단, 동의를 거부하시는 경우에는
        삼분의일 슬립큐브 매트리스 이용이 불가합니다. 회사 및 회사 업무수탁자,
        자문기관은 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한
        법률」에 따라 귀하의 민감정보(질병, 상해정보)를 처리(수집, 이용, 조회,
        제공)하고자 합니다.
        <br />
        <br />
        - 민감정보의 수집 이용 목적: 건강정보 수집 및 정보제공 서비스,
        건강/의료정보 데이터 분석
        <br />
        - 수집 이용할 민감정보의 내용: 앱/웨어러블 로그데이터, 건강정보 데이터,
        의료이용 데이터, 관리 데이터
        <br />- 민감정보의 보유 이용 기간: - 서비스 이용 인증 후 해지시까지 -
        수집한 정보를 이용하고자 하는 자의 이용목적이 끝날 때까지
      </ParagraphContent>
    </PageWrapper>
  );
};

export default CollectSensitiveInformation;
