import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicyThirdParty from "components/PrivacyPolicyThirdParty/PrivacyPolicyThirdParty";
import TermsOfService from "components/TermsOfService/TermsOfService";
import CollectSensitiveInformation from "components/CollectSensitiveInformation/CollectSensitiveInformation";
import SensitiveInformationThirdParty from "components/SensitiveInformationThirdParty/SensitiveInformationThirdParty";
import SleepCubePrivacyPolicy from "components/SleepCubePrivacyPolicy/SleepCubePrivacyPolicy";

function App() {
  return (
    <div className="page-wrapper">
      <Router>
        <Routes>
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route
            path="/privacy_policy_3rd_party"
            element={<PrivacyPolicyThirdParty />}
          />
          <Route path="/terms_of_service" element={<TermsOfService />} />
          <Route
            path="/collect_sensitive_information"
            element={<CollectSensitiveInformation />}
          />
          <Route
            path="/sensitive_information_3rd_party"
            element={<SensitiveInformationThirdParty />}
          />
          <Route
            path="/sleepcube_privacy_policy"
            element={<SleepCubePrivacyPolicy />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
